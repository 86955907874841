import React from "react";
import SEO from "../components/seo";
import Draft from "../components/Draft";

const DraftPage = () => (
  <>
    <SEO title="Draft" />
    <Draft />
  </>
);

export default DraftPage;
