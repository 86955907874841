import React, { useState, useEffect } from "react";
import { Chat } from "twitch-js";
import styled from "styled-components";
import Players from "./Players";
import Team from "./Team";

// Provide your token, username and channel. You can generate a token here:
// https://twitchtokengenerator.com
// https://twitchapps.com/tmi/
const username = "bustin";
const channel = "bustin";
const token = "oauth:divc0o4b1m2cuva564bd81jv4zjva7";

const DraftLayout = styled.div`
  position: relative;
`;

const TeamInfo = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  gap: 10px;
`;

export default function Draft() {
  const [players, setPlayers] = useState({});

  const joinChat = async () => {
    const chat = new Chat({
      username,
      token,
    });

    await chat.connect();
    await chat.join(channel);

    chat.on(`PRIVMSG`, message => {
      if (message.message.split(" - ").length === 3) {
        const data = message.message.split(" - ");

        setPlayers(p => {
          if (p.hasOwnProperty(message.username)) {
            if (p[message.username].team !== "none") {
              return p;
            } else if (p[message.username].team === "none") {
              return {
                ...p,
                [message.username]: {
                  ...p[message.username],
                  name: data[0],
                  rank: data[1],
                  role: data[2],
                },
              };
            }
          } else {
            return {
              ...p,
              [message.username]: {
                name: data[0],
                rank: data[1],
                role: data[2],
                team: "none",
                visible: true,
              },
            };
          }
        });
      }
    });
  };

  useEffect(() => {
    joinChat();
  }, []);

  const draftPlayer = (playerName, team) => {
    setPlayers(s => ({ ...s, [playerName]: { ...s[playerName], team: team } }));
  };

  const undraftPlayer = playerName => {
    setPlayers(s => ({
      ...s,
      [playerName]: { ...s[playerName], team: "none" },
    }));
  };

  const hidePlayer = playerName => {
    setPlayers(s => ({
      ...s,
      [playerName]: { ...s[playerName], visible: false },
    }));
  };

  return (
    <DraftLayout>
      <TeamInfo>
        <Players {...{ players, hidePlayer, draftPlayer }} />
        <Team side={"blue"} {...{ players, undraftPlayer }} />
        <Team side={"red"} {...{ players, undraftPlayer }} />
      </TeamInfo>
      <button
        style={{
          position: `absolute`,
          top: 0,
          left: -200,
        }}
        type="button"
        onClick={e => setPlayers({})}
      >
        Reset
      </button>
    </DraftLayout>
  );
}
