import React from "react";
import styled from "styled-components";

const TeamLayout = styled.div``;

const PlayersGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  .column {
    display: grid;
    gap: 10px;
    border: 1px solid grey;
    padding: 5px;
    align-items: space-between;
  }
  .players {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 2fr auto;
  }
  .close {
    cursor: pointer;
  }
`;

export default function Team({ side, players, undraftPlayer }) {
  return (
    <TeamLayout>
      <PlayersGrid>
        <div className="column">
          <div>{side} team</div>
          {Object.keys(players).length > 0 &&
            Object.keys(players).map(p => {
              const player = players[p];
              if (player.team === side) {
                return (
                  <div key={p} className={"players"}>
                    <div>{player["name"]}</div>
                    <div>{player["rank"]}</div>
                    <div>{player["role"]}</div>
                    <span
                      type="button"
                      className="close"
                      onClick={e => undraftPlayer(p)}
                    >
                      ✖
                    </span>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </PlayersGrid>
    </TeamLayout>
  );
}
